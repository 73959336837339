import React, { useEffect } from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

import { CardContainerStyled, Spinner } from "."



const PaymentCardDestination = (props) => {
  const host = window.location.host === 'iwaretravel.ca' ? 'https://www.desttravel.com/payment/checkout.aspx' : 'https://devweb.desttravel.com/payment/checkout.aspx';
  useEffect(() => {
    const paymentCheckoutIFrame = document.getElementById('DTGPaymentCheckoutIFrame');
    const paymentCheckoutCallback = (e) => {
      props.onPurchase(e);
    }
    
    paymentCheckoutIFrame.onload = function () {
      window.addEventListener('message', paymentCheckoutCallback);
    }

    return () => {
      window.removeEventListener('message', paymentCheckoutCallback);
    }
  }, []);

  return (
    <CardContainerStyled
      maxWidth="600px"
      css={css`
        margin: 0 auto;
        @media (min-width: 650px) {
          width: 600px;
        }
      `}
      borderRadius="var(--border-radius-lg)"
      borderThickness="1px"
      boxShadow="1.5rem 1.5rem 0.75rem var(--clr-neutral-200)"
    >
      <Wrapper>
        <div className="innerWrapper">
          {props.submitted 
            ? <Spinner />
            : <iframe style={{ width: '500px', height: '400px' }} id="DTGPaymentCheckoutIFrame" src={`${host}?bgcolor=transparent`} frameBorder="0" scrolling="no"></iframe>
          }
        </div>
      </Wrapper>
    </CardContainerStyled>
  )
}

const Wrapper = styled.div`
  .innerWrapper {
    padding: 2rem 4rem;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    row-gap: 1.5rem;
    column-gap: 4rem;
    justify-items: start;

    @media (max-width: 500px) {
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr;
    }
  }
  .spanAll {
    grid-column: 1/-1;
    width: 100%;
    justify-self: flex-start;
  }

  .inner {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.5rem;
  }
`

export default PaymentCardDestination
